import React from "react";
import { graphql } from "gatsby";

import { renderBlocks } from "../js/pageBlockRender";

import Layout from "../components/global/Layout";
import SEO from "../components/global/SEO";

import "../components/sass/blocks.scss";

const PageTemplate = ({ data }) => (
  <Layout>
    <SEO yoast={data.currentPage.yoast} />
    {renderBlocks(
      data.currentPage.acf !== null ? data.currentPage.acf.page_blocks : ""
    )}
  </Layout>
);

export default PageTemplate; 

export const pageQuery = graphql`
  query($id: String!) {
    currentPage: wordpressPage(id: { eq: $id }) {
      title
      id
      yoast {
        focuskw
        title
        metadesc
        opengraph_title
        opengraph_description
        opengraph_updated_time
        twitter_title
        twitter_description
        linkdex
        metakeywords
        meta_robots_noindex
        meta_robots_nofollow
        meta_robots_adv
        canonical
        redirect
        opengraph_url
        opengraph_site_name
        opengraph_type
      }
      acf {
        page_blocks {
          block_type {
            acf_fc_layout

            fwtt_eyebrow
            fwtt_alignment
            fwtt_title
            fwtt_text
            fwtt_bg_color

            dl_style

            hh_title
            hh_copy
            hh_background_video
            hh_poster {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 4000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }

            sdh_video
            sdh_context
            sdh_poster {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }

            csg_hide_toggle
            csg_context
            csg_case_studies {
              case_study {
                post_title
                categories
                post_type
                post_content
                post_excerpt
                acf {
                  case_study_key_properties
                  case_study_blocks {
                    block_type {
                      acf_fc_layout
                      csh_title
                    }
                  }
                }
              }
              size
              title_color
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 4000) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              overlay_bg_color
              content_va
              content_ha
            }

            q_intro
            q_quote
            q_quotee
            q_quote_image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 4000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }

            bi_business_intro
            bi_business_contact

            tpc_thought_piece {
              post_title
              post_excerpt
              acf {
                legacy
                thought_piece_blocks {
                  block_type {
                    acf_fc_layout
                    authors {
                      a_company
                      a_headshot {
                        alt_text
                        localFile {
                          childImageSharp {
                            fluid(quality: 100, maxWidth: 1000) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                          }
                        }
                      }
                      a_name
                      a_title
                    }
                  }
                }
              }
            }
            media_item {
              media_item_size
              media_item_image_or_video
              media_image {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 2000) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              media_video_url
              media_video_autoplay_or_click
            }
            pch_small_title
            pch_context
            pch_text
            pch_title
            pch_hero_image_title
            pch_hero_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }

            tct_small_title
            tct_text
            tct_has_circle
            tct_circle_color

            tcmts_media_style
            tcmts_media_placement
            tcmts_media {
              alt_text
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 4000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt_text
            }
            tcmts_text

            st_rows {
              client {
                post_title
              }
              brand_practices {
                title
              }
              experience_practices {
                title
              }
            }

            rcs_intro_title
            rcs_related_cases {
              case_study {
                post_title
                image {
                  alt_text
                  localFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 4000) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              image {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 4000) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }

            hero_eyebrow
            hero_header
            hero_header_size
            hero_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 4000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            hero_video_link

            single_column_light__dark_background
            single_column_text_block

            logo_carousel_cta {
              target
              title
              url
            }
            logo_carousel_items {
              logo_carousel_image {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 4000) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            tab_text_body_copy
            tab_text_image_header
            tab_text_elements {
              tab_text_title
              tab_text_image {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 4000) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            text_image_light__dark_background
            text_image_elements {
              image_left__right
              text_image_text_block
              text_image_text_block_image {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 4000) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              text_image_the_image {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 4000) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }

            enumerated_steps_header
            enumerated_steps_body_text
            enumerated_steps_cta {
              target
              title
              url
            }
            enumerated_steps
            enumerated_steps_the_text_blocks {
              text_block_header
              text_block_body_copy
            }
            enumerated_steps_background_image {
              alt_text
              localFile {
                publicURL
              }
            }

            accordion_items {
              accordion_header
              accordion_body_copy
            }
            accordion_cta {
              target
              title
              url
            }

            single_column_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 4000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/* tcmts_bg_color

  localFile {
    childImageSharp {
      fluid(quality: 100, maxWidth: 4000) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
*/
